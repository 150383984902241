import { useState, createRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '@ux/button';
import Spinner from '@ux/spinner';
import Dropdown from '@ux/dropdown';
import Input from '@ux/text-input';
import ReCAPTCHA from 'react-google-recaptcha';
import { request } from '@ux/util';
import Growl from '@ux/growl';

const { DropdownItem } = Dropdown;

import { useContext } from '../RenderContext';
import Recaptcha from '../Recaptcha';
import util from '../../util';


const stringRegex = /^(?!\s+$).*/;
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

type FormProps = {
  productIndex: number,
  name: string,
  email: string,
  subject: string,
  description: string
};

interface ContactFormProps {
  shopper?: {
    firstName: string,
    lastName: string,
  },
  setIncidentId: (a: string) => void,
  setMessageSent: (a: boolean) => void
}

const ContactForm = ({
  shopper,
  setIncidentId,
  setMessageSent
}: ContactFormProps) => {
  const {
    settings
  } = useContext();

  const { formatMessage } = useIntl();
  const { envPrefix, privateLabelId } = settings;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formData, setFormData] = useState<FormProps>({
    productIndex: 0,
    name: shopper ? [shopper.firstName, shopper.lastName].join(' ').trim() : '',
    email: '',
    subject: '',
    description: ''
  });
  let nameError = null;
  if (formData.name) {
    nameError = !stringRegex.test(formData.name) ? 'invalid' : false;
  }
  const [errors, setErrors] = useState<{
    [key: string]: string | boolean | null
  }>({
    name: nameError,
    email: null,
    subject: null,
    description: null
  });

  const updateErrorState = (name: string, value: string | number | null) => {
    if (name === 'productIndex' ||  typeof value === 'number') {
      return;
    }
    let state: boolean | string = false;

    if (!value || value.trim() === '') {
      state = 'required';
    }
    else {
      switch (name) {
        case 'name':
        case 'subject':
        case 'description':
          if (!stringRegex.test(value)) {
            state = 'invalid';
          }
          break;
        case 'email':
          if (!emailRegex.test(value)) {
            state = 'invalid';
          }
          break;
        default:
          break;
      }
    }
    setErrors((prev) => {
      return {
        ...prev,
        [name]: state
      };
    });

  };


  const handleFormSubmit = (values: {}) => {
    const url = `https://www.${envPrefix}secureserver.net/api/v1/contact/${privateLabelId}`;

    const options = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    request.post(url, values, options, (error: Error, response: { incidentId: string}) => {
      if (error) {
        Growl.addGrowlMessage({
          id: 'contact-us-error-growl',
          title: formatMessage({ id: 'contactUs.growls.error.title' }),
          children: formatMessage({ id: 'contactUs.growls.error.content' }),
          emphasis: 'critical',
          lifetime: 5000
        });

        return setIsSubmitting(false);
      }
      setIncidentId(response.incidentId);
      setMessageSent(true);
    });
  };

  const updateForm = (key: keyof FormProps, value: string | number | null) => {
    updateErrorState(key, value);
    setFormData({
      ...formData,
      [key]: value
    });
  };
  const products = settings?.env !== 'production' ? [0] : [
    15,
    7,
    56,
    43,
    3,
    9,
    11,
    6,
    25,
    18,
    8,
    148,
    349,
    57,
    24,
    16,
    190,
    0
  ];

  const hasErrors = !(errors.name || errors.email || errors.subject || errors.description);
  const allTouched = Object.keys(errors).every((key: string) => errors[key] !== null);
  const isSubmitDisabled = !hasErrors || isSubmitting || !allTouched;
  const recaptchaRef = createRef<ReCAPTCHA>();
  const triggerRecaptcha = () => {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
      recaptchaRef.current.execute();
    }
  };

  return (
    <div className="contactUs-form">
      { util.shouldRenderEnglishOnlySupportDisclaimer(settings) && (
        <p className="contactUs-englishOnly">
          <FormattedMessage id="contactUs.englishOnly" />
        </p>
      ) }
      <Dropdown
        id="contactUs-form-product"
        type="select"
        disabled={ isSubmitting }
        name="productId"
        selected={ [formData.productIndex] }
        label={ <span><FormattedMessage id="contactUs.form.labels.product" />&nbsp;</span> }
        required={ true }
        data-eid="storefront.contact_us.form.product.wrapper"
      >
        { products.map(key => (
          <DropdownItem
            key={ key }
            value={ key }
            onClick={ (e: { itemIndex: number }) => {
              updateForm('productIndex', e.itemIndex);
            } }
          >
            <FormattedMessage id={ `contactUs.form.products.${key}` } />
          </DropdownItem>
        )) }
      </Dropdown>
      <div className="row">
        <div className="col-md-6">
          <Input
            id="contactUs-form-name"
            name="name"
            label={ formatMessage({ id: 'contactUs.form.labels.name' }) }
            required={ true }
            disabled={ isSubmitting }
            onChange={ (val: string) => {
              updateForm('name', val);
            } }
            data-eid="storefront.contact_us.form.name.input"
            errorMessage={ errors.name && formatMessage({ id: `contactUs.form.${errors.name}` }) }
          />
        </div>
        <div className="col-md-6">
          <Input
            id="contactUs-form-email"
            name="email"
            type="email"
            label={ formatMessage({ id: 'contactUs.form.labels.emailAddress' }) }
            disabled={ isSubmitting }
            required={ true }
            onChange={ (val: string) => {
              updateForm('email', val);
            } }
            data-eid="storefront.contact_us.form.email.input"
            errorMessage={ errors.email && formatMessage({ id: `contactUs.form.${errors.email}` }) }
          />
        </div>
      </div>
      <Input
        id="contactUs-form-subject"
        name="subject"
        label={ formatMessage({ id: 'contactUs.form.labels.subject' }) }
        disabled={ isSubmitting }
        required={ true }
        data-eid="storefront.contact_us.form.subject.input"
        onChange={ (val: string) => {
          updateForm('subject', val);
        } }
        errorMessage={
          errors.subject &&
          formatMessage({ id: `contactUs.form.${errors.subject}` })
        }
      />
      <Input
        id="contactUs-form-description"
        name="description"
        label={ formatMessage({ id: 'contactUs.form.labels.description' }) }
        disabled={ isSubmitting }
        required={ true }
        onChange={ (val: string) => {
          updateForm('description', val);
        } }
        multiline
        size={ 3 }
        data-eid="storefront.contact_us.form.description.input"
        errorMessage={
          errors.description &&
          formatMessage({ id: `contactUs.form.${errors.description}` })
        }
      />
      <Recaptcha
        locale={ settings.locale }
        siteKey={ settings.recaptcha.siteKey }
        recaptchaRef={ recaptchaRef }
        onChange={ ({ recaptcha }: {
          recaptcha: string | null,
        }) => {
          if (recaptcha) {
            setIsSubmitting(true);
            handleFormSubmit({
              recaptcha,
              productId: products[formData.productIndex],
              name: formData.name,
              email: formData.email,
              subject: formData.subject,
              description: formData.description
            });
          }
        } }
      />
      <Button
        design="primary"
        type="submit"
        onClick={ triggerRecaptcha }
        disabled={ isSubmitDisabled }
        data-eid="storefront.contact_us.form.submit.click"
        text={ (
          <>
            { isSubmitting && (
              <Spinner size="sm" inline shade="dark" />
            ) }
            <FormattedMessage id="contactUs.form.button" />
          </>
        ) }
      />
    </div>
  );
};

export default ContactForm;
