import { Fragment } from 'react';
import { isMobile, useBreakpoints } from '@ux/component-utilities';
import { useContext } from '../RenderContext';
import Card from '../Card';
import DomainSearch from '../DomainSearch';
import AtMostOnePromotionBanner from '../PromotionBanners/AtMostOnePromotionBanner';

export const DomainRegistration = () => {
  const {
    tag
  } = useContext();

  const { breakpoint } = useBreakpoints();
  const product = tag?.products?.[0];
  const mobileView = isMobile(breakpoint);

  return (
    <Fragment>
      <AtMostOnePromotionBanner
        preference={ ['domains'] }
        skipNavigationCheck={ true }
      />
      <Card className="product-card">
        <div className="row">
          { mobileView && <DomainSearch /> }

          <div className="product-details">
            { !mobileView && <DomainSearch className="inline" /> }
            {product?.content && (
              <div
                className="product-description"
                dangerouslySetInnerHTML={{ __html: product.content }}
              />
            )}
            {product?.disclaimers && (
              <div
                className="product-disclaimers"
                dangerouslySetInnerHTML={{ __html: product.disclaimers }}
              />
            )}
          </div>
        </div>
      </Card>
    </Fragment>
  );
};

export default DomainRegistration;
