import Home from './components/Home';
import DomainTransfer from './components/DomainTransfer/wrapper';
import DomainRegistration from './components/DomainRegistration/wrapper';
import Products from './components/Products';
import LegalAgreements from './components/LegalAgreements';
import LegalAgreement from './components/LegalAgreements/agreement';
import ContactUs from './components/ContactUs';
import Unsubscribe from './components/Unsubscribe';
import Redemption from './components/Redemption';

const routes = [{
  key: 'home',
  path: '/',
  component: Home
}, {
  key: 'products/domain-transfer',
  path: '/products/domain-transfer',
  component: DomainTransfer
}, {
  key: 'products/domain-registration',
  path: '/products/domain-registration',
  component: DomainRegistration
}, {
  key: 'products',
  path: '/products/:tag',
  component: Products
}, {
  key: 'legalAgreements',
  path: '/legal-agreements',
  component: LegalAgreements
}, {
  key: 'legalAgreement',
  path: '/legal-agreement',
  component: LegalAgreement
}, {
  key: 'contactUs',
  path: '/contact-us',
  component: ContactUs
}, {
  key: 'unsubscribe',
  path: '/unsubscribe',
  component: Unsubscribe
}, {
  key: 'redemption',
  path: '/redemption',
  component: Redemption
},
// Component-less tokens so that `match-route` middleware doesn’t 404 on
// these paths.
{
  key: 'set-preference',
  path: '/set-preference'
}];

export default routes;
