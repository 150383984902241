import { FormattedMessage } from 'react-intl';
import Card from '../Card';
import Form from './form';

const DomainTransfer = () => (
  <Card className="product-card domain-transfer">
    <div className="product-details">
      <Form />
      <h4 className="product-title">
        <FormattedMessage id="domainTransfer.subtitle" />
      </h4>
      <div className="product-description">
        <ul>
          { ['forwarding', 'locking', 'control', 'registration', 'alerts'].map(key => (
            <li key={ key }>
              <h5>
                <strong>
                  <FormattedMessage id={ `domainTransfer.features.${key}.header` } />
                </strong>
              </h5>
              <p>
                <FormattedMessage id={ `domainTransfer.features.${key}.body` } />
              </p>
            </li>
          )) }
        </ul>

        <div className="text-muted">
          <h5>
            <FormattedMessage id="domainTransfer.steps.subtitle" />
          </h5>
          <ul>
            { ['unlock', 'authorize', 'verify', 'important', 'cancel'].map(key => (
              <li key={ key }>
                <FormattedMessage id={ `domainTransfer.steps.${key}` } />
              </li>
            )) }
          </ul>
        </div>
      </div>
    </div>
  </Card>
);

export default DomainTransfer;
