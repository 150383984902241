import { FormattedMessage } from 'react-intl';
import { URL } from '@ux/util';

import FilteredProducts from './FilteredProducts';
import { Product } from '../../types';
import { useContext } from '../RenderContext';

const filters = [{
  key: 'hdd',
  filter: (p: Product) => p.tags.includes('hdd')
}, {
  key: 'ssd',
  filter: (p: Product) => p.tags.includes('ssd')
}];

const productCardProps = {
  cta: 'configureYourServer'
};

export const DedicatedServer = () => {
  const { settings, tag } = useContext();
  const generateFooter = () => {
    const { isOnCustomDomain, privateLabelId, market } = settings;

    if ((market ?? '').indexOf('en-') !== 0) {
      return null;
    }

    let messageKey = 'viewSelfManaged';
    const url = new URL('/products/dedicated-server');

    if (tag?.id === 'dedicated-server') {
      messageKey = 'viewFullyManaged';

      url.set('query', { tag: 'managed-dedicated-server' });
    }

    if (!isOnCustomDomain) {
      url.set('query', {
        ...url.query,
        plid: privateLabelId
      });
    }

    return (
      <div className="col-xs-12">
        <p className="vps-switch">
          <FormattedMessage
            id={ `products.dedicatedServer.${messageKey}` }
            values={{
              a: (msg: string) => (
                <a href={ url }>{msg}</a>
              )
            }}
          />
        </p>
      </div>
    );
  };

  return (
    <FilteredProducts
      filters={ filters }
      productCardProps={ productCardProps }
      footerContent={ generateFooter() }
    />
  );
};

export default DedicatedServer;
