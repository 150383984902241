import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '@ux/button';

import { useContext } from '../RenderContext';

interface InterstitialProps {
  handleContinueAsGuest: () => void
}

const Interstitial = ({
  handleContinueAsGuest
}: InterstitialProps) => {
  const {
    settings: {
      envPrefix,
      privateLabelId,
      providesOwnSupport
    }
  } = useContext();

  const intl = useIntl();

  const [canInteract, setCanInteract] = useState(false);

  useEffect(() => {
    setCanInteract(true);
  }, []);

  const queryString = `?pl_id=${privateLabelId}&path=%2fcontact-us&app=www`;

  return (
    <div className="contactUs-interstitial">
      <div className="row">
        <div className="col-sm-8 offset-sm-2 col-md-6 offset-md-3">
          <p>
            <Button
              design="primary"
              href={ `https://sso.${envPrefix}secureserver.net/${queryString}` }
              data-eid="storefront.contact_us.interstitial.sign_in.click"
              text={ intl.formatMessage({ id: 'contactUs.interstitial.signIn' }) }
            />
          </p>
          <p>
            <FormattedMessage
              id="contactUs.interstitial.createPrompt"
              values={{
                a: (msg: string) => (
                  <a
                    href={ `https://sso.${envPrefix}secureserver.net/v1/account/create${queryString}` }
                    data-eid="storefront.contact_us.interstitial.create_account.click"
                  >
                    {msg}
                  </a>
                )
              }}
            />
          </p>
          { !providesOwnSupport &&
            <p>
              <Button
                design="secondary"
                onClick={ handleContinueAsGuest }
                data-eid="storefront.contact_us.interstitial.continue_as_guest.click"
                disabled={ !canInteract }
                text={ intl.formatMessage({ id: 'contactUs.interstitial.continueAsGuest' }) }
              />
            </p>
          }
        </div>
      </div>
    </div>
  );
};

export default Interstitial;
