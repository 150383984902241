import { FormattedMessage } from 'react-intl';
import { URL } from '@ux/util';

import FilteredProducts from './FilteredProducts';
import { Product } from '../../types';
import { useContext } from '../RenderContext';

export const Vps = () => {
  const {
    settings,
    tag
  } = useContext();
  const filters = [{
    key: 'performance',
    filter: (p: Product) => p.tags.includes('performance')
  }, {
    key: 'highPerformance',
    filter: (p: Product) => p.tags.includes('high-performance')
  }];

  const productCardProps = {
    cta: 'configureYourServer'
  };

  const generateFooter = () => {
    if (!/^en-/.test(settings.market ?? '')) {
      return null;
    }

    let messageKey = 'viewSelfManaged';
    const url = new URL('/products/vps');

    if (tag?.id === 'vps') {
      messageKey = 'viewFullyManaged';

      url.set('query', { tag: 'managed-vps' });
    }

    const { isOnCustomDomain, privateLabelId } = settings;

    if (!isOnCustomDomain) {
      url.set('query', {
        ...url.query,
        plid: privateLabelId
      });
    }

    return (
      <div className="col-xs-12">
        <p className="vps-switch">
          <FormattedMessage
            id={ `products.vps.${messageKey}` }
            values={{
              a: (msg: string) => (
                <a href={ url }>{msg}</a>
              )
            }}
          />
        </p>
      </div>
    );
  };

  return (
    <FilteredProducts
      filters={ filters }
      productCardProps={ productCardProps }
      footerContent={ generateFooter() }
    />
  );
};

export default Vps;
