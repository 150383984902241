import { ReactNode } from 'react';

const PageTitle = ({ children }: {
  children: ReactNode
}) => (
  <div className="ux-pagetitle">
    <div className="container">
      {children}
    </div>
  </div>
);

export default PageTitle;
