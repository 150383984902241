import { Fragment } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import Alert from '@ux/alert';
import { useBreakpoints, isMobile } from '@ux/component-utilities';
import Tooltip from '@ux/tooltip';

import PromotionBanner from './PromotionBanner';
import usePromotionBanner from './usePromotionBanner';

const HostingPromotionBanner = () => {
  const { breakpoint } = useBreakpoints();
  const intl = useIntl();
  const mobileView = isMobile(breakpoint);
  const {
    shouldRender,
    handleDismiss
  } = usePromotionBanner('sf.suppressBanner.hostingPromotion');

  if (!shouldRender) {
    return null;
  }

  return (
    <PromotionBanner
      component={ (
        <Alert
          id="hosting-promotion-banner-alert"
          title="Hosting Promotion Banner Alert"
          className="hostingPromotionBanner"
          emphasis="highlight"
          onClose={ handleDismiss }
        >
          <FormattedMessage id="home.hostingPromotionBanner.title" />
          { mobileView
            ? <Tooltip message={ intl.formatMessage({ id: 'home.hostingPromotionBanner.tooltip' }) } />
            : <Fragment>&nbsp;<FormattedMessage id="home.hostingPromotionBanner.tooltip" /></Fragment>
          }
        </Alert>
      ) }
    />
  );
};

export default HostingPromotionBanner;
