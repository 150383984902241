import { createContext, useContext as useCtxt } from 'react';
import { ContextProps } from '../types';

// context needs to have initial values matching its type
const emptyContextProps: ContextProps = {
  settings: {
    messages: {},
    hostname: '',
    envPrefix: '',
    locale: '',
    market: '',
    privateLabelId: 0,
    displayName: '',
    itc: '',
    recaptcha: {
      siteKey: ''
    },
    captcha: {
      siteKey: ''
    },
    useRecaptcha: false,
    appEnvAddress: '',
    displayTaxesAndFees: 'NONE',
    env: '',
    htmlDirection: '',
    isOnCustomDomain: false,
    location: {
      path: '',
      query: {
        itc: '',
        packageId: '',
        plan: '',
        plid: '',
        src: '',
        xs: ''
      }
    },
    switchboard: {}
  }
};

const RenderContext = createContext<ContextProps>(emptyContextProps);

export default RenderContext;

const Provider = RenderContext.Provider;

const useContext: () => ContextProps = () => useCtxt(RenderContext);

export {
  RenderContext,
  Provider,
  useContext
};
