import { promotions } from 'rcc-helpers';
import { useIntl, FormattedMessage } from 'react-intl';
import Tooltip from '@ux/tooltip';
import Alert from '@ux/alert';

import PromotionBanner from './PromotionBanner';
import usePromotionBanner from './usePromotionBanner';
import { RCCPromotion } from './types';

const SecurityBundlePromotionBanner = () => {
  const {
    handleDismiss,
    shouldRender
  } = usePromotionBanner('sf.suppressBanner.securityBundlePromotion');
  const intl = useIntl();

  if (!shouldRender) {
    return null;
  }

  const promoCode = promotions.data.find(
    (p: RCCPromotion) => p.key === '30-percent-off-security-bundle'
  ).iscCode.toUpperCase();

  return (
    <PromotionBanner
      component={ (
        <Alert
          id="security-bundle-promotion-banner-alert"
          title="Security Bundle Promotion Banner Alert"
          className="securityBundlePromotionBanner"
          emphasis="highlight"
          onClose={ handleDismiss }
        >
          <FormattedMessage
            id="home.securityBundlePromotionBanner.title"
            values={{
              promoCode: <strong>{promoCode}</strong>
            }}
          />
          <Tooltip
            message={
              intl.formatMessage({ id: 'home.securityBundlePromotionBanner.tooltip' })
            }
          />
        </Alert>
      ) }
    />
  );
};

export default SecurityBundlePromotionBanner;
