import { useState, useEffect, RefObject, useRef, FormEvent } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import Button from '@ux/button';
import Search from '@ux/search';

import { useContext } from '../RenderContext';
import util from '../../util';

interface OuterProps {
  className?: string,
}

const Form  = ({
  className
} : OuterProps) => {
  const {
    settings
  } = useContext();
  const [canInteract, setCanInteract] = useState(false);
  const intl = useIntl();

  const ref = useRef({}) as RefObject<HTMLInputElement>;

  const handleSearchClick = (e: FormEvent) => {
    e.preventDefault();

    const {
      envPrefix,
      privateLabelId,
      progId,
      itc
    } = settings;

    global.location = util.buildDomainTransferUrl({
      envPrefix,
      domain: ref.current?.value ?? '',
      privateLabelId,
      progId,
      itc
    });
  };

  useEffect(() => {
    setCanInteract(true);
  }, []);
  const SearchButton = (
    <Button
      design="primary"
      type="submit"
      className="search-form-button"
      data-eid="storefront.product.domain.transfer.button.click"
      disabled={ !canInteract }
      text={ intl.formatMessage({ id: 'domainTransfer.label' }) }
    />
  );
  return (
    <form className={ classNames(className, 'search-form') } onSubmit={ handleSearchClick }>
      <Search
        id="domainTransferSearch"
        button={ SearchButton }
        data-eid="storefront.product.domain.transfer.input"
        name="domainToCheck"
        placeholder={ intl.formatMessage({ id: 'domainTransfer.cta' }) }
        ref={ ref }
      />
    </form>
  );
};

export default Form;
