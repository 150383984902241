import { cookie } from '@ux/util';
import { useEffect, useState } from 'react';

const usePromotionBanner = (
  dismissCookieName: string
) => {
  const [shouldRender, setShouldRender] = useState(false);

  const handleDismiss = () => {
    cookie.set(dismissCookieName, '1');
    setShouldRender(false);
  };

  useEffect(() => {
    if (dismissCookieName && !cookie.get(dismissCookieName)) {
      setShouldRender(true);
    }
  }, []);

  return {
    shouldRender,
    handleDismiss
  };
};

export default usePromotionBanner;
