import { useState } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { useContext } from '../RenderContext';
import Button from '@ux/button';
import Search from '@ux/search';

interface OuterProps {
  className?: string,
  eidContext?: string,
  buttonDesign?: string
}

const DomainSearch = ({
  className,
  eidContext,
  buttonDesign
}: OuterProps) => {
  const {
    settings
  } = useContext();
  const intl = useIntl();
  const {
    envPrefix,
    privateLabelId
  } = settings;

  const [value, setValue] = useState('');
  const SearchButton = (
    <Button
      design={ buttonDesign }
      type="submit"
      className="search-form-button"
      data-eid={ `storefront.${eidContext}.domain.search.button.click` }
      text={ intl.formatMessage({ id: 'domainSearch.label' }) }
    />
  );
  return (
    <form
      className={ classNames('search-form', className) }
      method="GET"
      action={ `https://www.${envPrefix}secureserver.net/products/domain-registration/find` }
    >
      <input name="plid" type="hidden" value={ privateLabelId } />
      <Search
        id="domainSearch"
        button={ SearchButton }
        className="text-black"
        data-eid={ `storefront.${eidContext}.domain.search.input` }
        name="domainToCheck"
        placeholder={ intl.formatMessage({ id: 'domainSearch.cta' }) }
        value={ value }
        onChange={ setValue }
      />
    </form>
  );
};

DomainSearch.defaultProps = {
  eidContext: 'product',
  buttonDesign: 'primary'
};

export default DomainSearch;
