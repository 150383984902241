(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("ux"), require("prop-types"), require("react"), require("react-dom"), require("@ux/alert")["default"], require("@ux/button")["default"], require("@ux/dropdown")["default"], require("@ux/growl")["default"], require("@ux/modal")["default"], require("@ux/spinner")["default"], require("@ux/text")["default"], require("react-intl")["default"], require("@ux/util")["default"]);
	else if(typeof define === 'function' && define.amd)
		define(["ux", "prop-types", "react", "react-dom", "@ux/alert", "@ux/button", "@ux/dropdown", "@ux/growl", "@ux/modal", "@ux/spinner", "@ux/text", "react-intl", "@ux/util"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("ux"), require("prop-types"), require("react"), require("react-dom"), require("@ux/alert")["default"], require("@ux/button")["default"], require("@ux/dropdown")["default"], require("@ux/growl")["default"], require("@ux/modal")["default"], require("@ux/spinner")["default"], require("@ux/text")["default"], require("react-intl")["default"], require("@ux/util")["default"]) : factory(root["ux"], root["PropTypes"], root["React"], root["ReactDOM"], root["ux"]["Alert"], root["ux"]["Button"], root["ux"]["Dropdown"], root["ux"]["Growl"], root["ux"]["Modal"], root["ux"]["Spinner"], root["ux"]["Text"], root["ux"]["intl"], root["ux"]["utils"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(self, (__WEBPACK_EXTERNAL_MODULE__4361__, __WEBPACK_EXTERNAL_MODULE__3431__, __WEBPACK_EXTERNAL_MODULE__8036__, __WEBPACK_EXTERNAL_MODULE__6047__, __WEBPACK_EXTERNAL_MODULE__4625__, __WEBPACK_EXTERNAL_MODULE__4885__, __WEBPACK_EXTERNAL_MODULE__7753__, __WEBPACK_EXTERNAL_MODULE__7489__, __WEBPACK_EXTERNAL_MODULE__3361__, __WEBPACK_EXTERNAL_MODULE__2345__, __WEBPACK_EXTERNAL_MODULE__1645__, __WEBPACK_EXTERNAL_MODULE__3721__, __WEBPACK_EXTERNAL_MODULE__4646__) => {
return 