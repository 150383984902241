import { URL, request } from '@ux/util';

interface EnhancedError extends ErrorConstructor {
  key: string,
}

const errorWithKey = (key:string) => {
  const error = new Error('Error from /api/v1/redemptionCodes') as unknown as EnhancedError;

  error.key = key;

  return error;
};

export const redeemCode = (privateLabelId: string | number, code: string) => new Promise((resolve, reject) => {
  const url = new URL(`/api/v1/redemptionCodes/${privateLabelId}/${code}`);

  const baseOptions = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  // The `addtocart=true` version of the SAPI call does not provide granular
  // statuses on error. So make a status check request first to get a granular
  // status for user-facing-error purposes. But if the code is available,
  // immediately make add-to-cart call.

  request.get(url.href, baseOptions, (checkError: boolean, checkResponse: {
    status: string,
    success: boolean,
  }) => {
    if (checkError || !checkResponse) {
      return reject(errorWithKey('general'));
    }
    else if (checkResponse.status === 'Expired') {
      return reject(errorWithKey('expired'));
    }
    else if (checkResponse.status === 'Redeemed') {
      return reject(errorWithKey('alreadyUsed'));
    }
    else if (checkResponse.status === 'Revoked') {
      return reject(errorWithKey('revoked'));
    }
    else if (!checkResponse.success || checkResponse.status !== 'Available') {
      return reject(errorWithKey('general'));
    }

    const addToCartOptions = {
      ...baseOptions,
      query: {
        addToCart: true
      }
    };

    request.get(url.href, addToCartOptions, (addError: boolean, addResponse: {
      success: boolean,
    }) => {
      // This response doesn’t always include `status` so show general error
      // if unsuccessful.
      if (addError || !addResponse || !addResponse.success) {
        return reject(errorWithKey('general'));
      }

      resolve({});
    });
  });
});
