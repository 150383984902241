import { ReactNode, useState } from 'react';
import { Product } from '../../types';
import ProductsBase from './ProductsBase';
import TabFilters from '../TabFilters';

type Filters = {
  key: string;
  filter: (toCheck: Product) => boolean;
}[];

interface FilteredProductsProps {
  filters: Filters,
  productCardProps?: {
    cta: string
  },
  footerContent?: ReactNode,
}



const FilteredProducts = (props: FilteredProductsProps) => {
  const {
    filters,
    footerContent,
    productCardProps
  } = props;
  const [filterIndex, updateFilterIndex] = useState(0);

  const generateProducts = (allProducts: Array<Product>) => {
    const { filter } = filters[filterIndex];

    return allProducts.filter(filter);
  };

  const generateHeader = () => {
    return (
      <div className="col-xs-12">
        <TabFilters
          filters={ filters }
          defaultFilterIndex={ filterIndex }
          handleFilter={ (newIndex: number) => {
            updateFilterIndex(newIndex);
          } }
        />
      </div>
    );
  };
  return (
    <ProductsBase
      productCardProps={ productCardProps }
      headerContent={ generateHeader() }
      footerContent={ footerContent }
      generateProducts={ generateProducts }
    />
  );
};

export default FilteredProducts;
