import { FormattedMessage, useIntl } from 'react-intl';
import { TaxesAndFees } from '../../types';

interface DisclaimerProps {
  displayTaxesAndFees?: TaxesAndFees,
  tagId?: string,
  disclaimer?: {
    link?: string
  }
}

const Disclaimer = ({
  displayTaxesAndFees = 'NONE',
  tagId,
  disclaimer
}: DisclaimerProps) => {
  const intl = useIntl();
  const tagDisclaimer = intl.messages[`products.disclaimers.${ tagId }`];

  return (
    <div className="taxAndFeeDisclaimer">
      <div className="container">
        {tagDisclaimer && (
          <p>
            <FormattedMessage
              id={ `products.disclaimers.${tagId}` }
              // if a message doesnt have an <a> in, disclaimer.link will not be used,
              // even if it exists. If a link should be present but none is provided,
              // the link will be absent, with only the message inside the <a> present.
              values={{
                a: (msg: string) => disclaimer?.link ? (
                  <a href={ disclaimer.link }>{msg}</a>
                ) : msg
              }}
            />
          </p>
        )}
        {/* eslint-disable-next-line max-len */}
        <p data-eid={ `storefront.product.taxAndFeeDisclaimer.${displayTaxesAndFees}` }>
          <FormattedMessage
            id={ `products.taxAndFeeDisclaimers.${displayTaxesAndFees}` }
          />
        </p>
      </div>
    </div>
  );
};

export default Disclaimer;
