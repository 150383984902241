import { MouseEvent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Product } from '../../types';

interface TabProps {
  filters: {
    key: string,
    filter: (toCheck: Product) => boolean,
  }[],
  defaultFilterIndex: number;
  handleFilter: object;
}

const TabFilters = ({ filters, defaultFilterIndex = 0, handleFilter }:TabProps) => {
  const [filterIndex, setFilterIndex] = useState(defaultFilterIndex);
  const handleClick = (e: MouseEvent, i: number) => {
    e.preventDefault();
    setFilterIndex(i);
    if (typeof handleFilter === 'function') {
      handleFilter(i);
    }
  };

  return (
    <ul className="tab-filter">
      {filters.map((filter, i) => {
        if (i === filterIndex) {
          return (
            <li key={ filter.key } className="text-primary">
              <FormattedMessage
                id={ `products.filter.${filter.key}` }
              />
            </li>
          );
        }

        return (
          <li key={ filter.key } className="text-muted">
            <button /* eslint-disable-line jsx-a11y/anchor-is-valid */
              className="products-filter text-muted"
              onClick={ (e) => handleClick(e, i) }
            >
              <FormattedMessage
                id={ `products.filter.${filter.key}` }
              />
            </button>
          </li>
        );
      })}
    </ul>
  );
};

export default TabFilters;
