import { Fragment, useState, useRef, MutableRefObject, useEffect, ReactNode } from 'react';
import Helmet from 'react-helmet';
import classNames from 'classnames';

import { Breakpoints } from '@ux/component-utilities';
import Spinner from '@ux/spinner';
import ProductTitle from '../ProductTitle';
import ProductCard from '../ProductCard';
import AtMostOnePromotionBanner from '../PromotionBanners/AtMostOnePromotionBanner';
import util from '../../util';
import { Product } from '../../types';
import { useContext } from '../RenderContext';

// Map to store the mapping between the product count and the
// number of columns for xl, lg and md screen breakpoints.
// key : product count
// value: [xxl_column_count, lg_column_count, md_column_count]
const productCountColumnCountMap: {
  [key: number]: [number, number, number]
} = {
  1: [2, 2, 2],
  2: [2, 2, 2],
  3: [3, 3, 2],
  4: [4, 3, 2],
  5: [3, 3, 2],
  6: [3, 3, 2],
  7: [4, 3, 2]
};

const PRODUCT_COUNT_MAX = 7;
const GRID_COLUMN_COUNT = 12;

const getProductClassNames = (
  originalColumnCountRef: MutableRefObject<undefined | number>,
  productCount: number, shouldRenderSingleColumn: boolean
) => {
  // Using the originalColumnCountRef to check if it has a value. The value will be
  // set if there is a tab component on the page and user is toggling between the
  // tabs. The check is added as we want to maintain the initial column layout that
  // was rendered for the initial loaded tab.
  if (originalColumnCountRef.current) {
    productCount = originalColumnCountRef.current;
  }
  else {
    productCount = productCount <= PRODUCT_COUNT_MAX ? productCount : PRODUCT_COUNT_MAX;
    originalColumnCountRef.current = productCount;
  }

  const breakpoints = productCountColumnCountMap[productCount];
  const classes = classNames({
    'product-item': !shouldRenderSingleColumn,
    [`flex-xxl-${Math.floor(GRID_COLUMN_COUNT / breakpoints[0])}`]: !shouldRenderSingleColumn,
    [`flex-lg-${Math.floor(GRID_COLUMN_COUNT / breakpoints[1])}`]: !shouldRenderSingleColumn,
    [`flex-md-${Math.floor(GRID_COLUMN_COUNT / breakpoints[2])}`]: !shouldRenderSingleColumn
  });
  // Creating responsive classes dynamically by dividing the total grid column count
  // by the pre-defined number of columns for a particular breakpoint for a given
  // product count
  return classes;
};

interface ProductsProps {
  productCardProps?: {
    cta: string,
  }
  generateProducts?: (products: Array<Product>) => Array<Product>,
  headerContent?: ReactNode,
  footerContent?: ReactNode,
}

const ProductsBase = ({
  productCardProps,
  generateProducts,
  headerContent,
  footerContent
}: ProductsProps) => {
  const {
    tag,
    settings
  } = useContext();
  const {
    id,
    products = []
  } = tag ?? {};
  const {
    privateLabelId
  } = settings ?? {};
  const [canInteract, setCanInteract] = useState(false);

  const hasProducts = products && products.length;
  if (!hasProducts) {
    util.redirect(`/?plid=${privateLabelId}`);
    return (
      <div />
    );
  }
  const generatedProducts = generateProducts ? generateProducts(products) : products;
  const shouldRenderSingleColumn = id ? ['domain-registration', 'domain-transfer'].includes(id) : false;
  // A ref to store the column count value from the intital tab when
  // the component gets loaded in case we have tab component in the
  // JSX
  const originalColumnCountRef: MutableRefObject<undefined | number> = useRef();
  const productItemClassname = getProductClassNames(
    originalColumnCountRef, products?.length ?? 1,
    shouldRenderSingleColumn
  );
  useEffect(() => {
    setCanInteract(true);
  }, []);
  return (
    <div className={ classNames('products', id) }>
      {/* we only want this to show up in products and the modal
      is at base level so we can't add it in products.scss */}
      <Helmet>
        <style>
          {`
            .ux-disrupt-backdrop {
              background-color: rgb(0 0 0 / 60%);
              backdrop-filter: blur(0.125rem);
              z-index: 2;
            }
          `}
        </style>
      </Helmet>
      <AtMostOnePromotionBanner
        preference={ ['securityBundle', 'hosting'] }
        productTag={ id }
        skipNavigationCheck={ true }
      />
      { tag && (
        <ProductTitle { ...tag } />
      )}

      <div className="container">
        <div className="row">
          { !canInteract
            ? (
              <div className="text-center products--spinner">
                <Spinner inline size="lg" />
              </div>
            )
            : (
              <Fragment>
                {headerContent}
                <div className="col-xs-12">
                  <div className="product-list">
                    {generatedProducts.map((item) => (
                      <div key={ item.id } className={ productItemClassname }>
                        <Breakpoints breakpoints={ ['mobile', 'phablet', 'tablet'] }>
                          {({ breakpoint }: {
                            breakpoint: string,
                          }) => (
                            <ProductCard
                              breakpoint={ breakpoint }
                              product={ item }
                              { ...productCardProps }
                            />)
                          }
                        </Breakpoints>
                      </div>
                    ))}
                  </div>
                </div>
                {footerContent}
              </Fragment>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default ProductsBase;
