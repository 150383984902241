import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Modal from '@ux/modal';
import { cookie } from '@ux/util';
import SiblingSet from '@ux/sibling-set';
import Button from '@ux/button';
import { RenderContext } from '../RenderContext';

const cookieName = 'suppressForwardedFromModal';

const ForwardedFromModal = () => {
  const { settings } = useContext(RenderContext);
  const { displayName, forwardedFromDisplayName } = settings;
  const [shouldRenderModal, setShouldRenderModal] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    const isSuppressedByCookie = !!cookie.get(cookieName);

    setShouldRenderModal(!!forwardedFromDisplayName && !isSuppressedByCookie);
  }, []);

  const handleCloseModal = () => {
    cookie.set(cookieName, 'true', { path: '/' });

    setShouldRenderModal(false);
  };
  if (!shouldRenderModal) {
    return null;
  }
  return (
    <Modal
      id="forwarded-from-modal"
      title="Forwarded From Modal"
      className="text-black forwarded-from"
      onClose={ handleCloseModal }
      focusLock={ true }
    >
      <p>
        <strong>
          <FormattedMessage
            id="home.forwardedFrom.body"
            values={{
              displayName,
              forwardedFromDisplayName
            }}
          />
        </strong>
      </p>
      <SiblingSet>
        <Button
          id="modal-close-button"
          design="primary"
          onClick={ handleCloseModal }
          text={ intl.formatMessage({ id: 'home.forwardedFrom.close' }) }
        />
      </SiblingSet>
    </Modal>
  );
};

export default ForwardedFromModal;
