import React, { JSX, Fragment } from 'react';
import { IntlProvider, IntlShape, WithIntlProps } from 'react-intl';
import { Route } from 'react-router-dom';
import { init as initApm } from '@elastic/apm-rum';
import { ApmRoutes } from '@elastic/apm-rum-react';
import Growl from '@ux/growl';

import routes from './routes';
import { Provider } from '../src/components/RenderContext';
import Disclaimer from './components/Disclaimer';
import './vendors-styles';
import { ContextProps } from './types';

type Prop = ((props: {}) => JSX.Element) | (React.FC<WithIntlProps<{
  intl: IntlShape;
}>> & {
  WrappedComponent: React.ComponentType<{
      intl: IntlShape;
  }>;
});

const Storefront = ({ renderContext }: {
  renderContext: ContextProps
}) => {
  const getApmEnv = () => {
    const hostname = renderContext.settings.hostname;
    if (hostname === 'www.dev-secureserver.net') {
      return 'dev';
    }
    if (hostname === 'www.test-secureserver.net') {
      return 'test';
    }
    return 'production';
  };

  initApm({
    serviceName: 'sf',
    environment: getApmEnv(),
    /* eslint-disable no-process-env */
    serverUrl: process.env.APM_SERVER_URL,
    serviceVersion: process.env.ELASTIC_APM_SERVICE_VERSION
    /* eslint-enable no-process-env */
  });
  return (
    <IntlProvider locale={ renderContext.settings.locale } messages={ renderContext.settings.messages }>
      <Fragment>
        <Provider value={ renderContext }>
          <ApmRoutes>
            {routes.filter(r => !!r.component).map((props: {
              key: string,
              path: string,
              component?: Prop,
            }) => {
              const {
                key,
                path,
                component: Page
              } = props;
              return (
                <Route
                  key={ key }
                  path={ path }
                  element={ Page ? <Page /> : null }
                />
              );
            })}
          </ApmRoutes>
        </Provider>
        <Disclaimer
          disclaimer={ renderContext.tag?.disclaimer }
          tagId={ renderContext.tag?.id }
          displayTaxesAndFees={ renderContext.settings.displayTaxesAndFees }
        />
        <Growl />
      </Fragment>
    </IntlProvider>
  );
};

export default Storefront;
