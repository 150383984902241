import { useIntl } from 'react-intl';

import withPageTitle from '../../hoc/withPageTitle';
import ProductTitle from '../ProductTitle';
import DomainTransfer from './';

export const DomainTransferWrapper = () => {
  const intl = useIntl();

  return (
    <div className="products domain-transfer domain-actions">
      <ProductTitle
        imageId="domainTransfer"
        title={ intl.formatMessage({ id: 'domainTransfer.title' }) }
      />
      <div className="container">
        <DomainTransfer />
      </div>
    </div>
  );
};

export default withPageTitle(DomainTransferWrapper);
