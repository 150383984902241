import { FormattedMessage } from 'react-intl';

import ProductCard from '../ProductCard';
import { useContext } from '../RenderContext';

export const Super = () => {
  const {
    tag
  } = useContext();
  const products = tag?.products ?? [];

  return (
    <div className="home super">
      <div className="home-hero bg-primary-o-highlight text-center">
        <div className="container">
          <h1 className="headline-brand">
            <FormattedMessage id="superHome.hero.title" />
          </h1>
          <p><FormattedMessage id="superHome.hero.subtitle" /></p>
        </div>
      </div>

      <div className="home-products">
        <div className="container">
          <div className="row">
            { products.map(product => (
              <div key={ product.id } className="col-lg-6">
                <ProductCard product={ product } />
              </div>
            )) }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Super;
