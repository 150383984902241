import { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import {
  FormattedMessage,
  useIntl
} from 'react-intl';
import Button from '@ux/button';
import Modal from '@ux/modal';
import { isMobile } from '@ux/component-utilities';
import Card from '../Card';
import util from '../../util';
import { useContext } from '../RenderContext';
import { Product } from '../../types';

const ProductCard = ({
  breakpoint,
  product,
  cta = 'addToCart'
}: {
  breakpoint?: string,
  product: Product,
  cta?: string,
}) => {
  const {
    settings
  } = useContext();
  const {
    envPrefix,
    privateLabelId,
    itc
  } = settings;

  const intl = useIntl();

  const ref = useRef({}) as RefObject<HTMLDivElement>;

  const [canInteract, setCanInteract] = useState(false);
  const [shouldShowDisclaimersModal, setShouldShowDisclaimersModal] = useState(false);

  const showDisclaimersButton = useMemo(() => !isMobile(breakpoint), [breakpoint]);

  useEffect(() => {
    setCanInteract(true);
  }, []);

  const hasDisclaimers = product.disclaimers != null && product.disclaimers !== '';
  const shouldShowDisclaimersButton = hasDisclaimers && showDisclaimersButton;
  const shouldShowDisclaimersText = hasDisclaimers && !shouldShowDisclaimersButton;

  const details = (
    <div
      className="product-description"
      dangerouslySetInnerHTML={{ __html: product.content }}
    />
  );

  const disclaimers = (
    <div dangerouslySetInnerHTML={{ __html: product.disclaimers ?? '' }} />
  );

  const price = (
    <p className="product-price">
      { product.salePrice && (
        <span>
          <s className="product-amount text-muted">
            { product.listPrice }
          </s>&nbsp;
        </span>
      ) }

      <strong className="product-amount text-primary">
        { product.salePrice || product.listPrice }
        { /* keep these together to preserve the breaking space */ }
      </strong> <FormattedMessage id={ `products.terms.${product.oneTimeCharge ? 'each' : product.term}` }>
        { text => <span className="product-term">{text}</span> }
      </FormattedMessage>
    </p>
  );

  const action = `https://www.${envPrefix}secureserver.net/api/v1/cart/${privateLabelId}?itc=${itc}&redirect=true`;

  const items = [{
    id: product.id,
    quantity: 1
  }];

  const button = (
    <form method="POST" action={ action }>
      <input type="hidden" name="items" value={ JSON.stringify(items) } />

      <Button
        design="primary"
        className="product-addToCart"
        type="submit"
        disabled={ !canInteract }
        data-eid={ `storefront.product.${util.snakeCase(product.id)}.add_to_cart.button.click` }
        text={ intl.formatMessage({ id: `products.${cta}` }) }
      />
    </form>
  );


  const disclaimersButton = (
    <div className="product-disclaimers-btn">
      <Button
        size="small"
        design="inline"
        text={ intl.formatMessage({ id: 'products.details.disclaimers' }) }
        onClick={ () => setShouldShowDisclaimersModal(true) }
      />
    </div>
  );

  const productDisclaimers = (
    <div className="product-disclaimers-text">
      { disclaimers }
    </div>
  );

  const productBody = (
    <div>
      <h4 className="product-title">{ product.alias || product.title }</h4>
      { price }
      { button }
      { details }
      { shouldShowDisclaimersText && productDisclaimers }
    </div>
  );

  const disclaimersModal = (
    <Modal
      id={ product.id }
      onClose={ () => setShouldShowDisclaimersModal(false) }
    >
      <div className="disclaimer-modal-content">
        <h4 className="product-title">
          <FormattedMessage id="products.details.disclaimers" />
        </h4>
        { productDisclaimers }
      </div>
    </Modal>
  );



  const detailsClassNames = classNames(
    'product-details'
  );

  const footerDisclaimerButton = shouldShowDisclaimersButton ? disclaimersButton : null;

  return (
    <Card
      className="product-card"
      footer={ footerDisclaimerButton }
    >
      <div className={ detailsClassNames } ref={ ref }>
        { productBody }
      </div>
      { shouldShowDisclaimersModal && disclaimersModal }
    </Card>
  );
};

export default ProductCard;
