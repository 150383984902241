import { FormattedMessage } from 'react-intl';
import Button from '@ux/button';
import { useContext } from '../RenderContext';
import Card from '../Card';
import util from '../../util';
import classNames from 'classnames';

interface ContentProps {
  hasSupport: boolean
}

const Content = ({ hasSupport } : ContentProps) => {
  const {
    settings,
    support,
    settings: {
      envPrefix,
      privateLabelId
    }
  } = useContext();

  return (
    // eslint-disable-next-line max-len
    <div className={ classNames('contactUs-sidebar container row', { 'col-lg-3': hasSupport, 'card-deck': !hasSupport }) }>
      <div className={ classNames({ 'col-sm-7 col-lg-7 card-deck': !hasSupport, 'col-sm-6 col-lg-12': hasSupport }) }>
        <Card className="contactUs-sidebar-help">
          <h2><FormattedMessage id="contactUs.sidebar.help.title" /></h2>
          <p><FormattedMessage id="contactUs.sidebar.help.cta" /></p>
          <p>
            <Button
              design="secondary"
              href={ `https://www.${envPrefix}secureserver.net/help/?pl_id=${privateLabelId}` }
            >
              <FormattedMessage id="contactUs.sidebar.help.button" />
            </Button>
          </p>
        </Card>
      </div>

      <div className={ classNames({ 'col-sm-4 col-lg-4 card-deck': !hasSupport, 'col-sm-6 col-lg-12': hasSupport }
      ) }>
        { support && !!support.technical && (
          <Card className="contactUs-sidebar-support">
            <h2><FormattedMessage id="contactUs.sidebar.support.title" /></h2>
            { util.shouldRenderEnglishOnlySupportDisclaimer(settings) && (
              <p className="contactUs-englishOnly"><FormattedMessage id="contactUs.englishOnly" /></p>
            ) }
            <p>
              <span className="contactUs-sidebar-support-label">
                {!hasSupport && <FormattedMessage id="contactUs.sidebar.support.technical" />}
              </span>
              <strong className="contactUs-sidebar-support-value">
                <a href={ `tel:${util.stripNonNumerals(support.technical)}` }>{support.technical}</a>
              </strong>
            </p>
          </Card>
        ) }
      </div>
    </div>
  );
};

export default Content;
