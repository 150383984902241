import { BrowserRouter } from 'react-router-dom';
import { hydrate } from 'react-dom';

import Storefront from './storefront';
import { ContextProps } from './types';

hydrate(
  <BrowserRouter>
    <Storefront renderContext={ global.__RENDER_CONTEXT__ as unknown as ContextProps } />
  </BrowserRouter>,
  document.getElementById('body')
);

delete global.__RENDER_CONTEXT__;
