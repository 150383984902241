import { RefObject } from 'react';
import Captcha, { ReCAPTCHA } from 'react-google-recaptcha';

const Recaptcha = ({
  recaptchaRef,
  locale,
  siteKey,
  onChange
}: {
  locale: string,
  siteKey: string,
  recaptchaRef: RefObject<ReCAPTCHA>,
  onChange: ({
    recaptcha
  }: {
    recaptcha: string | null
  }) => void
}) => {
  return (
    <div className="captcha-recaptcha">
      <Captcha
        hl={ locale }
        sitekey={ siteKey }
        onChange={ recaptcha => onChange({ recaptcha }) }
        size="invisible"
        ref={ recaptchaRef }
      />
    </div>
  );
};

export default Recaptcha;
