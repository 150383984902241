import { useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import Search from '@ux/search';
import Alert from '@ux/alert';
import Button from '@ux/button';
import { useContext } from '../RenderContext';

import util from '../../util';
import { redeemCode } from './util';

const Redemption = () => {
  const {
    settings
  } = useContext();
  const [state, setState] = useState({
    isSubmitting: false,
    error: null as null | string
  });
  const [redemptionCode, setRedemptionCode] = useState('');

  const handleRedemptionCodeSubmit = () => {
    // Search component doesn’t support a disabled state, so bail if currently
    // submitting.
    if (state.isSubmitting) {
      return;
    }
    // Can also bail with `noRedemptionCode` error if redemption code is empty.
    else if (!redemptionCode) {
      return setState({
        isSubmitting: false,
        error: 'noRedemptionCode'
      });
    }

    setState({
      isSubmitting: true,
      error: null
    });

    const { privateLabelId, envPrefix } = settings;

    redeemCode(privateLabelId, redemptionCode)
      .then(() => {
        // Intentionally not resetting state here so that the user can’t
        // re-submit a different redemptionCode while the cart redirect is loading.

        util.redirect(`https://cart.${envPrefix}secureserver.net/?plid=${privateLabelId}`);
      })
      .catch(error => {
        setState({
          isSubmitting: false,
          error: error.key || 'general'
        });
      });
  };
  const intl = useIntl();
  const button = <Button
    design="control"
    id="redemption-form-submit-button"
    title="Redemption Form Submit Button"
    text={ intl.formatMessage({ id: 'redemption.form.submit' }) }
    onClick={ handleRedemptionCodeSubmit }
  />;

  return (
    <div className="bg-primary-o-highlight">
      <div className="container redemption">
        <h1 className="headline-brand text-center">
          <FormattedMessage id="redemption.title" />
        </h1>
        {state.error && (
          <Alert
            id="redemption-error-alert"
            title="Redemption Error Alert"
            emphasis="warning"
            dismissible="false"
          >
            <FormattedMessage id={ `redemption.form.error.${state.error}` }/>
          </Alert>
        )}

        <Search
          id="redemption-search"
          title="Redemption Search"
          placeholder={ intl.formatMessage({ id: 'redemption.form.placeholder' }) }
          button={ button }
          onChange={ setRedemptionCode }
        />
      </div>
    </div>
  );
};

export default Redemption;
