import withPageTitle from '../../hoc/withPageTitle';
import { useContext } from '../RenderContext';
import ProductTitle from '../ProductTitle';
import DomainRegistration from './';

export const DomainRegistrationWrapper = () => {
  const {
    tag
  } = useContext();
  return (
    <div className="products domain-registration">
      {
        tag && (
          <ProductTitle { ...tag } />
        )
      }
      <div className="container">
        <DomainRegistration />
      </div>
    </div>
  );
};

export default withPageTitle(DomainRegistrationWrapper);
