import camelCase from 'camelcase';

import * as svgs from './svgs';

type Props = { id: string };
type ValidId = keyof typeof svgs;

const isValidId = (id: string, validIds: Array<string>): id is ValidId => validIds.includes(id);

const ProductIcon = ({ id }: Props) => {
  const camelCaseId = camelCase(id);
  const validIds = Object.keys(svgs);
  const icon = isValidId(camelCaseId, validIds) ? svgs[camelCaseId] : '';

  return (
    <div
      className="product-icon"
      dangerouslySetInnerHTML={{ __html: icon }}
    />
  );
};

export default ProductIcon;
