import { useEffect, useState, ReactNode } from 'react';
import Config from '@mcx/configure';

import { useContext } from '../RenderContext';

const Configuration = () => {
  const { settings: { env, privateLabelId, location, messages } } = useContext();
  const { plan, packageId, src, itc, xs } = location.query;

  const [shouldRenderConfig, setShouldRenderConfig] = useState(false);

  useEffect(() => {
    setShouldRenderConfig(() => {
      if (window && global.window) {
        return true;
      }
      return false;
    });
  }, []);

  let content: string | ReactNode = '';

  if (shouldRenderConfig) {
    const initProps = {
      environment: env,
      packageId: plan || packageId,
      plid: privateLabelId,
      src,
      itc,
      xs,
      content: {
        loadingMessage: messages['config.loadingMessage'],
        apiError: messages['config.apiError']
      }
    };

    content = <Config initProps={ initProps } />;
  }

  return <div className="mcx-wrapper">{content}</div>;
};

export default Configuration;
