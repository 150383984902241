import classNames from 'classnames';
import { ReactNode, ReactElement } from 'react';

const Card = ({ className, children, footer }: {
  className?: string,
  children: ReactNode,
  footer?: ReactElement | null
}) => (
  <div className={ classNames('card ux-card', className) }>
    <div className="card-block">
      {children}
    </div>
    {footer != null && (
      <div className="card-block card-block-footer">{ footer }</div>
    )}
  </div>
);

export default Card;
