import { useContext } from '../RenderContext';
import DomainPromotionBanner from './DomainPromotionBanner';
import HostingPromotionBanner from './HostingPromotionBanner';
import SecurityBundlePromotionBanner from './SecurityBundlePromotionBanner';
import { Navigation, Promotions } from '../../types';


interface Props {
  preference?: Array<string>,
  skipNavigationCheck?: boolean,
  productTag?: string
}

const isOptedInToPromotion = (
  promotions?: Promotions,
  key?: string
) => !!promotions && !!promotions.find(p => p.key === key);

// Tokens for how and when to render a given banner.
// - `component`: the component to render if selected
// - `promotionKey`: for checking opt-in status
// - `productIsNotHidden`: for confirming that products related to this
//   promotion have not been hidden by reseller.
// - `productIsApplicable`: for confirmating that promotion applies to a
//   specified product tag.
const banners = {
  domains: {
    component: DomainPromotionBanner,
    promotionKey: 'year-long-domain-promotion',
    productIsNotHidden: (navigation?: Navigation) => !!navigation &&
      !navigation?.domains?.isHidden &&
      !navigation?.domains?.children?.domainRegistration?.isHidden,
    productIsApplicable: (tag: string) => ['domain-registration'].includes(tag)
  },
  hosting: {
    component: HostingPromotionBanner,
    promotionKey: 'hosting-promotions-for-yearly-terms',
    productIsNotHidden: (navigation?: Navigation) => !!navigation &&
      !navigation?.hosting?.isHidden,
    productIsApplicable: (tag: string) => [
      'cpanel',
      'plesk',
      'wordpress',
      'business',
      'vps',
      'dedicated-server'
    ].includes(tag)
  },
  securityBundle: {
    component: SecurityBundlePromotionBanner,
    promotionKey: '30-percent-off-security-bundle',
    productIsNotHidden: (navigation?: Navigation) => !!navigation &&
      !navigation?.hosting?.isHidden &&
      !navigation?.security?.isHidden,
    productIsApplicable: (tag: string) => [
      'cpanel',
      'plesk',
      'wordpress',
      'business',
      'ssl',
      'website-backup',
      'website-security'
    ].includes(tag)
  }
};

const AtMostOnePromotionBanner = ({
  preference = [],
  skipNavigationCheck,
  productTag
}: Props) => {
  const {
    navigation,
    promotions
  } = useContext();
  let PromotionBanner = null;

  for (let n = 0; n < preference.length; n++) {
    const banner = banners[preference[n] as keyof typeof banners];

    if (!banner) {
      break;
    }


    const isOptedIn = isOptedInToPromotion(promotions, banner.promotionKey);

    const productIsNotHidden = !!skipNavigationCheck || banner.productIsNotHidden(navigation);

    const productIsApplicable = !productTag || banner.productIsApplicable(productTag);
    if (isOptedIn && productIsNotHidden && productIsApplicable) {
      PromotionBanner = banner.component;

      break;
    }
  }
  if (!PromotionBanner) {
    return null;
  }
  return <PromotionBanner />;
};

export default AtMostOnePromotionBanner;
