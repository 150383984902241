import { Fragment } from 'react';
import {
  injectIntl,
  FormattedMessage
} from 'react-intl';
import classNames from 'classnames';

import { useContext } from '../RenderContext';
import DomainSearch from '../DomainSearch';
import ProductIcon from '../ProductIcon';
import AtMostOnePromotionBanner from '../PromotionBanners/AtMostOnePromotionBanner';
import util from '../../util';
import ForwardedFromModal from './forwardedFromModal';
import { Tag } from '../../types';

const PROFESSIONAL_EMAIL = 'professional-email';
const MICROSOFT_365 = 'microsoft-365';

const getColClass = (count: number) => {
  if (count <= 2) {
    return `col-md-6 col-lg-5 col-xl-4`;
  }
  return 'col-md-4';
};

/*
 Sanitize the email products based on user opted preferences.
 If user opts in for both professional-email and m365 we need to
 default to professional-email, hence removing the m365 product.
 If user opts in for either professional-email or m365 we need to
 show the selected preference.
*/
const sanitizeEmailProducts = (productList: Tag[]) => {
  if (productList.length === 0) return productList;

  const email = productList.filter(product => product.id === PROFESSIONAL_EMAIL);
  const m365 = productList.filter(product => product.id === MICROSOFT_365);

  if (email.length === 1  && m365.length === 1) {
    productList = productList.filter(product => product.id !== MICROSOFT_365);
  }

  return productList;
};

export const Turnkey = () => {
  const context = useContext();
  const {
    domainRegistrationProduct,
    settings,
    settings: {
      displayName,
      heroImage,
      privateLabelId
    },
    tags,
    support,
    navigation
  } = context;

  const productCalloutKeys = util.getProductCalloutKeys(navigation);
  const showCpanel = productCalloutKeys.includes('cpanel');
  const showEmail = productCalloutKeys.includes('professionalEmail');
  const showDomainRegistration = productCalloutKeys.includes('domainRegistration');
  const showMicrosoft365 = productCalloutKeys.includes('microsoft365');
  const showDomainRegistrationAndLoaded = showDomainRegistration && !!domainRegistrationProduct;
  const calloutTagIds = [
    showCpanel && 'cpanel',
    showEmail && 'professional-email',
    showDomainRegistration && 'domain-registration',
    showMicrosoft365 && 'microsoft-365'
  ].filter(Boolean);

  const calloutProducts = sanitizeEmailProducts(tags ? tags.filter(t => calloutTagIds.includes(t.id)) : []);
  let headLine;

  if (showDomainRegistrationAndLoaded) {
    const { tld, listPrice, salePrice } = domainRegistrationProduct;

    if (salePrice) {
      headLine = (
        <div className="home-hero-tld">
          <ProductIcon id="hotDeals" />
          <div className="home-hero-tld-pricing">
            <span>
              <FormattedMessage
                id="home.hero.saleTldTitle"
                values={{
                  tld: tld.toUpperCase(),
                  listPrice,
                  s: (msg: string) => (
                    <s>{msg}</s>
                  )
                }}
              />
            </span>
            <span>
              <FormattedMessage
                id="home.hero.salePriceTitle"
                values={{ salePrice }}
              />
            </span>
          </div>
        </div>
      );
    }
    else {
      headLine = <FormattedMessage id="home.hero.tldTitle" values={{ tld: tld.toUpperCase(), listPrice }} />;
    }
  }
  else {
    headLine = <FormattedMessage id="home.hero.title" values={{ displayName }} />;
  }
  return (
    <Fragment>
      <AtMostOnePromotionBanner preference={ ['securityBundle', 'hosting', 'domains'] } />

      <ForwardedFromModal />

      <div className="home">
        <div className={ classNames('home-hero text-center', { 'home-hero-image': !!heroImage }) }
          style={{ backgroundImage: heroImage && `url(${heroImage})` }}>
          <div className={ classNames('container', { 'home-hero-text': !!heroImage }) }>
            <h1 className="headline-brand">
              {headLine}
            </h1>
            { showDomainRegistration && (
              <div>
                { showCpanel && (
                  <p className="home-hero-subtitle"><FormattedMessage id="home.hero.subtitle" /></p>
                )}
                <DomainSearch eidContext="home" buttonDesign="primary" />
              </div>
            )}
          </div>
        </div>

        { calloutProducts.length > 0 && (
          <div className="home-products">
            <div className="container">
              <h2 className="sr-only"><FormattedMessage id="home.products.title" /></h2>
              <div className="row home-products-row">
                { calloutProducts.map(tag => {
                  const colClass = getColClass(calloutProducts.length);

                  return (
                    <div key={ tag.id } className={ `col-xs-12 ${colClass} text-center` }>
                      <div className="home-products-product" data-testid={ tag.id }>
                        <ProductIcon id={ tag.imageId } />
                        <h3>
                          <a
                            href={ `/products/${tag.id}?plid=${privateLabelId}` }
                            data-eid={ `storefront.home.product_highlights.${tag.id}.link.click` }
                          >{ tag.title }</a>
                        </h3>
                        <p>{ tag.subtitle }</p>
                      </div>
                    </div>
                  );
                }) }
              </div>
            </div>
          </div>
        ) }

        { support && support.technical && (
          <div className="home-contact bg-faded text-center">
            <div className="container">
              <div className="home-contact-info">
                <h2><FormattedMessage id="home.contact.title" /></h2>
                <p><FormattedMessage id="home.contact.body" /></p>
                <p className="lead">
                  <FormattedMessage
                    id="home.contact.cta"
                    values={{
                      supportPhoneNumber: support.technical,
                      a: (msg: string) => (
                        <a href={ `tel:${util.stripNonNumerals(msg)}` }>{msg}</a>
                      )
                    }}
                  />
                </p>
                { util.shouldRenderEnglishOnlySupportDisclaimer(settings) && (
                  <p className="contactUs-englishOnly"><FormattedMessage id="contactUs.englishOnly" /></p>
                ) }
              </div>
            </div>
          </div>
        ) }
      </div>
    </Fragment>
  );
};

export default injectIntl(Turnkey);
