import { FormattedMessage } from 'react-intl';

import withPageTitle from '../../hoc/withPageTitle';
import { useContext } from '../RenderContext';
import PageTitle from '../../components/PageTitle';
import Card from '../../components/Card';
import { LegalAgreement, LegalAgreements as LegalAgreementsType } from '../../types';

const generateLink = (key: string, privateLabelId: number, legalAgreements: LegalAgreementsType) => {
  const props: {
    className: string,
    href?: string,
    target?: string
  } = {
    className: 'legalAgreements-link'
  };

  const token = legalAgreements.find((d: LegalAgreement) => d.key === key);

  if (token?.href) {
    props.href = token.href;
    props.target = '_blank';
  }
  else {
    props.href = `/legal-agreement?id=${token?.id}&plid=${privateLabelId}`;
  }

  return (
    <a { ...props }><FormattedMessage id={ `legalAgreements.documentTitles.${key}` } /></a>
  );
};

const LegalAgreements = () => {
  const context = useContext();
  const {
    settings: {
      privateLabelId,
      displayName
    },
    legalAgreements = []
  } = context;
  return (
    <div className="legalAgreements">
      <PageTitle>
        <h1 className="headline-brand"><FormattedMessage id={ `legalAgreements.title` } /></h1>
      </PageTitle>

      <div className="container">
        <div className="row">
          <div className="col-md-12 legalAgreements-main">
            <Card>
              <h2>
                <FormattedMessage id={ `legalAgreements.fullTitle` } values={{ displayName: displayName }} />
              </h2>
              <p>
                <FormattedMessage id={ `legalAgreements.description` } values={{ displayName: displayName }} />
              </p>
              <p className="legalAgreements-utos">
                { generateLink('universal-terms-of-service-agreement', privateLabelId, legalAgreements) }
              </p>
            </Card>
          </div>

          { ['agreement', 'policy'].map(type => (
            <div key={ type } className={ `col-md-6 legalAgreements-${type}` }>
              <Card>
                <h2><FormattedMessage id={ `legalAgreements.types.${type}.title` } /></h2>
                <ul>
                  { legalAgreements.filter((d: LegalAgreement) => d.type === type).map(document => (
                    <li key={ document.key }>
                      { generateLink(document.key, privateLabelId, legalAgreements) }
                    </li>
                  )) }
                </ul>
              </Card>
            </div>
          )) }
        </div>
      </div>
    </div>
  );
};

export default withPageTitle(LegalAgreements);
