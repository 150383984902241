import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Alert from '@ux/alert';
import Spinner from '@ux/spinner';

import withPageTitle from '../../hoc/withPageTitle';
import { useContext } from '../RenderContext';
import PageTitle from '../PageTitle';
import Card from '../Card';
import MainContent from './content';
import Interstitial from './interstitial';
import util from '../../util';
import Form from './form';

interface IShopper {
  firstName: string,
  lastName : string,
  shopperId : string
}

const ContactUs = () => {
  const {
    settings,
    support
  } = useContext();
  const { providesOwnSupport = false } = settings;
  const [continueAsGuest, setContinueAsGuest] = useState(false);
  const [isShopperLoaded, setIsShopperLoaded] = useState(false);
  const [shopper, setShopper] = useState<IShopper>();
  const [incidentId, setIncidentId] = useState<string>();
  const [messageSent, setMessageSent] = useState(false);
  const { email } = support || {};
  const usesGoDaddyEmail = email?.includes('godaddy.com') || email?.includes('secureserver.net');

  useEffect(() => {
    util.getShopper(settings, (__, res) => {
      setShopper(res || {
        firstName: '',
        lastName: '',
        shopperId: ''
      });
      setIsShopperLoaded(true);
    });
  }, []);

  const isSignedIn = isShopperLoaded && !!shopper?.shopperId;

  const showForm = providesOwnSupport && !usesGoDaddyEmail;
  const Body = () => {
    const withValues = {
      incidentId,
      strong: (msg: string) => (
        <strong>{msg}</strong>
      )
    };
    const values = incidentId ? withValues : {};
    const cardMessage =
      <FormattedMessage
        id={ incidentId ? 'contactUs.confirmation.message' : 'contactUs.confirmation.customSupportMessage' }
        { ...values }
      />;

    if (!isShopperLoaded) return (
      <div className="text-center">
        <Spinner inline size="lg" />
      </div>
    );
    if (messageSent) return (
      <Alert
        id="message-sent-alert"
        title="Message Sent Success Alert"
        emphasis="success"
        dismissible="false"
      >
        <strong className="headline-primary">
          <FormattedMessage id="contactUs.confirmation.header" />
        </strong>
        {' '}
        {cardMessage}
      </Alert>
    );
    else if (isSignedIn || continueAsGuest) {
      return (
        <Form shopper={ shopper } setMessageSent={ setMessageSent } setIncidentId={ setIncidentId } />
      );
    }
    return (
      <Interstitial handleContinueAsGuest={ () => setContinueAsGuest(true) } />
    );
  };

  return (
    <div className="contactUs">
      <PageTitle>
        <h1 className="headline-brand">
          <FormattedMessage id="contactUs.title" />
        </h1>
      </PageTitle>

      <div className="container">
        <div className="row">
          <MainContent hasSupport={ showForm } />
          {showForm &&
            <div className="col-lg-8">
              <Card className="contactUs-main mr-lg-5">
                <h2>
                  <FormattedMessage id="contactUs.form.title" />
                </h2>
                <Body />
              </Card>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default withPageTitle(ContactUs);
