import { useEffect, useState } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import Alert from '@ux/alert';
import Button from '@ux/button';
import Spinner from '@ux/spinner';
import Checkbox from '@ux/checkbox';
import SiblingSet from '@ux/sibling-set';

import Growl from '@ux/growl';
import { request } from '@ux/util';
import { useContext } from '../RenderContext';
import Card from '../Card';
import PageTitle from '../PageTitle';

export const errorGrowl = (intl: IntlShape, type: string) => {
  Growl.addGrowlMessage({
    id: `unsubscribe-${type}-error-growl`,
    title: intl.formatMessage({ id: 'unsubscribe.growls.error.title' }),
    children: intl.formatMessage({ id: 'unsubscribe.growls.error.content' }),
    emphasis: 'critical',
    lifetime: 5000
  });
};

type Subscriptions = Array<{listName: string, displayName: string}>;
const Unsubscribe = () => {
  const {
    settings: {
      privateLabelId,
      envPrefix,
      displayName,
      location: {
        query: {
          ee
        }
      }
    },
    emailAddress
  } = useContext();
  const intl = useIntl();
  const [isProcessing, setIsProcessing] = useState(true);
  const [unsubscribed, setUnsubscribed] = useState(false);
  const [subscriptions, setSubscriptions] = useState<Subscriptions>([]);
  const [subscriptionsToCancel, setSubscriptionsToCancel] = useState<Array<string>>([]);

  const getSubscriptions = () => {
    const url = `https://gui.${envPrefix}secureserver.net/v1/subscription/list`;
    const options = {
      headers: {
        'Content-Type': 'application/json'
      },
      query: {
        EncryptedEmailAddress: ee,
        plid: privateLabelId
      }
    };
    request.get(url, options, (error: Error, response: {
      subscriptions: Subscriptions
    }) => {
      if (error) {
        setIsProcessing(false);
        return errorGrowl(intl, 'get');
      }
      setIsProcessing(false);
      setSubscriptions(response.subscriptions);
      if (response.subscriptions.length === 0) {
        setUnsubscribed(true);
      }
    });
  };

  useEffect(() => {
    getSubscriptions();
  }, []);

  const handleCheckboxToggle = (_: unknown, e: {
    event: {
      target: {
        value: string,
        checked: boolean
      }
    }
  }) => {
    const { value = '', checked } = e.event.target || {};
    if (checked) {
      setSubscriptionsToCancel([...subscriptionsToCancel, value]);
    }
    else {
      setSubscriptionsToCancel(subscriptionsToCancel.filter(s  => s !== value));
    }
  };

  // Delete subscriptions
  const handleUnsubscribeClick = () => {
    setIsProcessing(true);

    const body = {
      EncryptedEmailAddress: ee,
      ListNames: subscriptionsToCancel
    };

    const options = {
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    };

    const url = `https://gui.${envPrefix}secureserver.net/v1/subscription/unsubscribe`;

    request.del(url, options, (error: Error) => {
      if (error) {
        setIsProcessing(false);

        return errorGrowl(intl, 'del');
      }

      setIsProcessing(false);
      setUnsubscribed(true);
    });
  };

  let content;
  if (isProcessing) {
    content = (
      <div className="text-center">
        <Spinner inline="true" size="lg" />
      </div>
    );
  }
  else if (unsubscribed) {
    content = (
      <Card className="unsubscribe-card">
        <h2><FormattedMessage id="unsubscribe.card.unsubscribed.title"/></h2>
        <Alert
          id="unsubscribed-success-alert"
          title="Unsubscribed Success Alert"
          emphasis="success"
          dismissible="false"
        >
          <FormattedMessage id="unsubscribe.card.unsubscribed.alert" />
        </Alert>
        <p>
          <FormattedMessage
            id="unsubscribe.card.unsubscribed.description"
            values={{
              emailAddress,
              strong: (msg: string) => (
                <strong>{msg}</strong>
              )
            }}
          />
        </p>
        <Button
          design="primary"
          href={ `https://account.${envPrefix}secureserver.net/preferences?plid=${privateLabelId}` }
          data-eid="storefront.unsubscribe.email_preferences_button.click"
        >
          <FormattedMessage id="unsubscribe.card.unsubscribed.button" />
        </Button>
      </Card>
    );
  }
  else {
    content = (
      <Card className="unsubscribe-card">
        <h2><FormattedMessage id="unsubscribe.card.default.title"  values={{ displayName }}/></h2>
        <p>
          <FormattedMessage
            id="unsubscribe.card.default.description"
            values={{
              emailAddress,
              strong: (msg: string) => (
                <strong>{msg}</strong>
              )
            }}
          />
        </p>

        <SiblingSet>
          { subscriptions?.map(s => (
            <>
              <Checkbox
                key={ s.listName }
                value={ s.listName }
                id={ s.displayName }
                name={ s.displayName }
                label={ s.displayName }
                onChange={ handleCheckboxToggle }
              />
              <br/>
            </>
          )) }
        </SiblingSet>
        <br/>
        <Button
          design="primary"
          type="submit"
          onClick={ handleUnsubscribeClick }
          data-eid="storefront.unsubscribe.unsubscribe_button.click"
          disabled={ subscriptionsToCancel.length === 0 }
        >
          <FormattedMessage id="unsubscribe.card.default.button" />
        </Button>
      </Card>
    );
  }
  return (
    <div className="unsubscribe">
      <PageTitle>
        <h1 className="headline-brand">
          <FormattedMessage id="unsubscribe.title" />
        </h1>
      </PageTitle>

      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-10 offset-sm-1 col-lg-8 offset-lg-2">
            { content }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Unsubscribe;
