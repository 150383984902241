import { useContext } from '../RenderContext';
import withPageTitle from '../../hoc/withPageTitle';
import DedicatedServer from './DedicatedServer';
import Vps from './Vps';
import Config from '../Config';
import util from '../../util';
import ProductsBase from './ProductsBase';


const Products = () => {
  const {
    tag,
    settings
  } = useContext();
  const { query } = settings.location;

  if (util.shouldRenderConfig(query)) {
    return <Config />;
  }
  if (tag) {
    if (['vps', 'managed-vps'].includes(tag.id)) {
      return <Vps />;
    }
    if (['dedicated-server', 'managed-dedicated-server'].includes(tag.id)) {
      return <DedicatedServer />;
    }
  }
  return <ProductsBase />;
};

export default withPageTitle(Products);
