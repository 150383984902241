import classNames from 'classnames';

import PageTitle from './PageTitle';
import ProductIcon from './ProductIcon';
import { ReactNode } from 'react';

export type ProductTitleProps = {
  imageId: string,
  title: string,
  description?: string,
  children?: ReactNode
};

export const IconLayout = ({
  imageId,
  title,
  description,
  children
}: ProductTitleProps) => (
  <PageTitle>
    <div className={ classNames('product-header-container', !!description && 'has-description') }>
      <ProductIcon id={ imageId } />
      <div className="product-icon-text">
        <h1 className="headline-brand">
          { title }
        </h1>
        { description && (
          <p className="product-header-description">{ description }</p>
        ) }
        { children }
      </div>
    </div>
  </PageTitle>
);


export const ProductTitle = (props: ProductTitleProps) => <IconLayout { ...props }/>;

export default ProductTitle;
