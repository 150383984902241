import { useState } from 'react';
import { FormattedMessage, IntlShape, injectIntl } from 'react-intl';
import Modal from '@ux/modal';
import Button from '@ux/button';
import { useContext } from '../RenderContext';
import PageTitle from '../../components/PageTitle';
import Card from '../../components/Card';
import util from '../../util';

const LegalAgreement = ({
  intl
}: {
  intl: IntlShape
}) => {
  const context = useContext();
  const {
    settings,
    legalAgreement
  } = context;
  const initialShowDisclaimer = !((settings?.market ?? '').startsWith('en-'));
  if (!legalAgreement) {
    util.redirect(`/legal-agreements?plid=${settings.privateLabelId}`);
    return null;
  }
  const [showDisclaimer, setShowDisclaimer] = useState(initialShowDisclaimer);
  const handleCloseDisclaimer = () => {
    setShowDisclaimer(false);
  };
  return (
    <div className="legalAgreements">
      <PageTitle>
        <h1 className="headline-brand"><FormattedMessage id="legalAgreements.title" /></h1>
      </PageTitle>
      <div className="container">
        <div className="row">
          <Card>
            {legalAgreement && (
              <div dangerouslySetInnerHTML={{ __html: legalAgreement.body }} />
            )}
          </Card>
        </div>
      </div>

      { showDisclaimer && (
        <Modal
          id="show-disclaimer-modal"
          title="Show Disclaimer Modal"
          onClose={ handleCloseDisclaimer }
        >
          <p><FormattedMessage id="legalAgreements.translationDisclaimer.body" /></p>
          <Button
            design="primary"
            onClick={ handleCloseDisclaimer }
            text={ intl.formatMessage({ id: 'legalAgreements.translationDisclaimer.action' }) }
          />
        </Modal>
      ) }
    </div>
  );
};

export default injectIntl(LegalAgreement);
