import { ComponentType, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

import util from '../util';
import { useContext } from '../components/RenderContext';

function withPageTitle<T extends {}>(WrappedComponent: ComponentType<T>) {
  return (props: T) => {
    const intl = useIntl();

    const { settings: { displayName, location: { path } } } = useContext();
    const pageNameLocaleKey = util.getPageNameLocaleKey(path);
    const pageNameLocaleValue = (pageNameLocaleKey &&
      intl.formatMessage({ id: `page.title.${pageNameLocaleKey}` }))
      || '';

    return (
      <Fragment>
        <Helmet>
          <title>{ (pageNameLocaleValue && `${pageNameLocaleValue} | ${displayName}`) || displayName }</title>
        </Helmet>
        <WrappedComponent { ...props } />
      </Fragment>
    );
  };
}

export default withPageTitle;
