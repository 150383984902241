import { FormattedMessage } from 'react-intl';
import { promotions } from 'rcc-helpers';
import Tooltip from '@ux/tooltip';
import Alert from '@ux/alert';

import PromotionBanner from './PromotionBanner';
import usePromotionBanner from './usePromotionBanner';
import { RCCPromotion, RCCPromotionPricing } from './types';

const DomainPromotionBanner = () => {
  const {
    shouldRender,
    handleDismiss
  } = usePromotionBanner('sf.suppressBanner.domainPromotion');

  if (!shouldRender) {
    return null;
  }

  const tlds = promotions.data
    .find((promotion: RCCPromotion) => promotion.key === 'year-long-domain-promotion')
    .pricing.map((productPricing: RCCPromotionPricing) => '.' + productPricing.product.toUpperCase())
    .sort();

  return (
    <PromotionBanner
      component={ (
        <Alert
          id="domain-promotion-banner-alert"
          title="Domain Promotion Banner Alert"
          className="domainPromotionBanner"
          emphasis="highlight"
          onClose={ handleDismiss }
        >
          <FormattedMessage id="home.domainPromotionBanner" />
          <Tooltip message={ tlds.join(', ') } />
        </Alert>
      ) }
    />
  );
};

export default DomainPromotionBanner;
